.header-container {
  border-left: 1px solid #fafafa;
  border-right: 1px solid #fafafa;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.75);
  position: relative;
  z-index: 101;
  background-color: #fff;

  &.ultimate-hero-position {
    box-shadow: none;
    border-bottom: 1px solid #E3E5E6;
  }

  &.header-default {
    display: block;
  }

  &.header-wizard {
    box-shadow: none;
    border-bottom: 1px solid #D1D4D6;
  }
}

.header {
  height: 70px;
  display: flex;
  align-items: center;

  .logo svg {
    color: #00af1a;
  }

  .boast {
    div {
      font-size: 15px;
      float: left;
      margin-right: 25px;
      line-height: 35px;

      .million-moves {
        width: 33px;
      }

      .checks {

        svg {
          height: 27px;
          width: 21px;
        }

        width: 15px;
      }

      .reviews {
        width: 22px;
      }

      .professional {
        width: 17px;
      }

      svg {
        vertical-align: middle;
        height: 20px;
        margin-right: 9px;
      }

    }

    > div:last-child {
      margin-right: 0;
    }
  }
}

.header-container.header-trust {
  display: block;

  .sd-container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 13px;
    padding-right: 13px;
  }

  @media (min-width: @screen-sm) {
    .sd-container {
      width: 720px;
    }
  }
  @media (min-width: @screen-md) {
    .sd-container {
      width: 940px;
    }
  }
  @media (min-width: @screen-lg) {
    .sd-container {
      width: 1260px;
    }
  }
}

.header-container.header-trust .sd-container .header {

  .boast {
    div {
      margin-right: 5px;

    }

    .trust-bar-head {
      margin-right: 5px;
      font-size: 14px;
      font-weight: bold;
      display: block;

      .trust-component {
        margin-right: 13px;
        display: block;

        > svg {
          width: auto;
          height: 28px;

          text {
            font-size: 14px;
            font-weight: bold;
            fill: #5e5f61;
          }
        }

      }
    }
  }
}

// region HOME-3070 landing page experiment
.header-container.header-clean{
  border-left: none;
  border-right: none;
  border-bottom: 1px solid @home-gray-200;
  box-shadow: none;
  .header {
    height: 64px;
    @media (min-width: @screen-md) {
        height: 70px;
    }
  }
  .sd-container {
    max-width: 1226px;
  }

    .hamburger-button .patty {
    border-color: @home-gray-600;
  }

}
// endregion
