@import 'theme.less';
@import 'variables.less';

.affix ~ .footer-wrap {
  margin-bottom: 86px;
}

.footer-wrap {
  font-size: 11px;
  border-top: 1px solid #dadada;
  padding-top: 50px;
  background-color: #fff;

  // region HOME-3070 landing page experiment
  &.home_b2c_new_landingpage-experiment {
    color: @home-gray-700;
    padding: 40px 0;
    .footer{
      .container {
        .row {
          >div {
            &:last-child {
              display: none;
            }
            @media (min-width: 992px) {
                width: 25%;
            }
          }
        }
      }
    }
    .footer-bottom {
      display: none;
    }
  }
    // endregion

  @media (min-width: @screen-sm) {
    font-size: 15px;
  }

  h6 {
    font-weight: bold;

    @media (min-width: @screen-md) {
      margin-bottom: 27px;
    }
  }

  .menu {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      line-height: 1.75em;
    }
  }

  .logo {
    line-height: 1.6em;

    svg {
      display: block;
      max-width: 90%;
      margin-bottom: 10px;
      color: #716f70;
    }

    img {
      max-height: 40px;
      width: auto;
      margin-bottom: 10px;
    }
  }

  .img-label {
    width: 16px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;

    svg {
      width: 16px;
      height: 16px;
      margin-top: 3px;
    }
  }

  .email {
    margin-bottom: 3px;
  }

  a {
    color: #5e5f61;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .links {
    font-size: 14px;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      float: left;
      padding-right: 10px;
      margin-right: 10px;
      line-height: 30px;
      border-right: 1px solid #dadada;

      &:last-child {
        border-right: none;
      }
    }
  }

  .footer-bottom {
    border-top: 1px solid #dadada;

    .row {
      padding-top: 16px;
      margin-bottom: 10px;
    }
  }

  .footer-image {
    background-image: url("/build/img/footer/handyman.jpg");
    height: 286px;
    max-width: 286px;
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
  }
}
