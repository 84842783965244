.sd-m-0 {
  margin: 0 !important;
}

.sd-m-3 {
  margin: 3rem !important;
}

.sd-mb-0 {
  margin-bottom: 0 !important;
}

.sd-mt-0_25 {
  margin-top: 4px !important;
}

.sd-mt-0_5 {
  margin-top: 8px !important;
}

.sd-mt-sm-0 {
  @media (min-width: @screen-sm) {
    margin-top: 0 !important;
  }
}

.sd-mt-1 {
  margin-top: 16px !important;
}

.sd-mt-1_5 {
  margin-top: 24px !important;
}

.sd-mt-2 {
  margin-top: 32px !important;
}

.sd-mt-0 {
  margin-top: 0 !important;
}

.sd-mt-md-0 {
  @media (min-width: @screen-md) {
    margin-top: 0 !important;
  }
}

.sd-mt-md-1 {
  @media (min-width: @screen-md) {
    margin-top: 16px !important;
  }
}

.sd-mt-lg-3_5 {
  @media (min-width: @screen-lg) {
    margin-top: 56px !important;
  }
}

.sd-mr-0_25 {
  margin-right: 4px !important;
}

.sd-mr-0_5 {
  margin-right: 8px !important;
}

.sd-mr-1 {
  margin-right: 16px !important;
}

.sd-mr-1_5 {
  margin-right: 24px !important;
}

.sd-mr-2 {
  margin-right: 32px !important;
}

.sd-mr-2_5 {
  padding-right: 40px !important;
}

.sd-mr-sm-1 {
  @media (min-width: @screen-sm) {
    margin-right: 16px !important;
  }
}

.sd-mr-md-2 {
  @media (min-width: @screen-md) {
    margin-right: 32px !important;
  }
}

.sd-mr-xs-0_5 {
  @media (min-width: @screen-xs) {
    margin-right: 8px !important;
  }
}

.sd-mr-sm-0_5 {
  @media (min-width: @screen-sm) {
    margin-right: 8px !important;
  }
}

.sd-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.sd-my-0_25 {
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.sd-my-0_5 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.sd-my-sm-1 {
  @media (min-width: @screen-sm) {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
}

.sd-mb-0_25 {
  margin-bottom: 4px !important;
}

.sd-mb-0_5 {
  margin-bottom: 8px !important;
}

.sd-mb-1 {
  margin-bottom: 16px !important;
}

.sd-mb-1_25 {
  margin-bottom: 20px !important;
}

.sd-mb-1_5 {
  margin-bottom: 24px !important;
}

.sd-mb-2 {
  margin-bottom: 32px !important;
}

.sd-mb-3 {
  margin-bottom: 48px !important;
}

.sd-mb-xs-0 {
  @media (min-width: @screen-xs) {
    margin-bottom: 0 !important;
  }
}

.sd-mb-sm-0 {
  @media (min-width: @screen-sm) {
    margin-bottom: 0 !important;
  }
}

.sd-mb-md-0 {
  @media (min-width: @screen-md) {
    margin-bottom: 0 !important;
  }
}

.sd-mb-sm-0_5 {
  @media (min-width: @screen-sm) {
    margin-bottom: 8px !important;
  }
}

.sd-mb-sm-1_5 {
  @media (min-width: @screen-sm) {
    margin-bottom: 24px !important;
  }
}

.sd-mb-md-1 {
  @media (min-width: @screen-md) {
    margin-bottom: 16px !important;
  }
}

.sd-mb-sm-2 {
  @media (min-width: @screen-sm) {
    margin-bottom: 32px !important;
  }
}

.sd-mb-md-2 {
  @media (min-width: @screen-md) {
    margin-bottom: 32px !important;
  }
}

.sd-mb-lg-2 {
  @media (min-width: @screen-lg) {
    margin-bottom: 32px !important;
  }
}

.sd-mb-lg-2_5 {
  @media (min-width: @screen-lg) {
    margin-bottom: 40px !important;
  }
}

.sd-mb-lg-3_5 {
  @media (min-width: @screen-lg) {
    margin-bottom: 56px !important;
  }
}

.sd-ml-0_25 {
  margin-left: 4px !important;
}

.sd-ml-0_5 {
  margin-left: 8px !important;
}

.sd-p-0 {
  padding: 0 !important;
}

.sd-p-1 {
  padding: 16px !important;
}

.sd-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sd-px-0_25 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.sd-px-1_5 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.sd-py-1_5 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.sd-p-2 {
  padding: 32px !important;
}

.sd-pb-md-3_5 {
  @media (min-width: @screen-md) {
    padding-bottom: 56px !important;
  }
}

.sd-pt-1 {
  padding-top: 16px !important;
}

.sd-pt-2 {
  padding-top: 32px !important;
}

.sd-pt-2_5 {
  padding-top: 40px !important;
}

.sd-pt-3 {
  padding-top: 48px !important;
}

.sd-pt-3_5 {
  padding-top: 56px !important;
}

.sd-pt-md-1_5 {
  @media (min-width: @screen-sm) {
    padding-top: 24px !important;
  }
}

.sd-p-sm-1_25 {
  @media (min-width: @screen-sm) {
    padding: 20px !important;
  }
}

.sd-pt-md-0 {
  @media (min-width: @screen-md) {
    padding-top: 0 !important;
  }
}

.sd-pt-lg-3_5 {
  @media (min-width: @screen-lg) {
    padding-top: 56px !important;
  }
}

.sd-pt-lg-5 {
  @media (min-width: @screen-lg) {
    padding-top: 80px !important;
  }
}

.sd-pt-lg-5 {
  @media (min-width: @screen-lg) {
    padding-top: 80px !important;
  }
}

.sd-py-sm-4_5 {
  @media (min-width: @screen-sm) {
    padding-top: 72px !important;
    padding-bottom: 72px !important;
  }
}

.sd-py-xs-0_25 {
  @media (max-width: @screen-xs) {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
}

.sd-py-md-4_5 {
  @media (min-width: @screen-md) {
    padding-top: 72px !important;
    padding-bottom: 72px !important;
  }
}

.sd-py-lg-4_5 {
  @media (min-width: @screen-lg) {
    padding-top: 72px !important;
    padding-bottom: 72px !important;
  }
}

.sd-pl-md-2 {
  @media (min-width: @screen-md) {
    padding-left: 32px !important;
  }
}

.sd-pr-0 {
  padding-right: 0 !important;
}

.sd-pr-1 {
  padding-right: 16px !important;
}
.sd-pr-2 {
  padding-right: 32px !important;
}
.sd-pr-3 {
  padding-right: 48px !important;

}
.sd-pr-md-0 {
  @media (min-width: @screen-md) {
    padding-right: 0 !important;
  }
}
.sd-pr-lg-0 {
  @media (min-width: @screen-lg) {
    padding-right: 0 !important;
  }
}
.sd-pl-0 {
  padding-left: 0 !important;
}

.sd-pb-0 {
  padding-bottom: 0 !important;
}

.sd-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}
