.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-start {
  text-align: left !important;
}

.text-dark {
  color: #000000;
}

.p-small {
  font-size: 14px;
}
