.page-container {
  flex: 1 0 auto;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.grid-row {
  margin-bottom: 30px;
}

.text-container {
  max-width: 770px;
}

.modal-backdrop.show {
  opacity: .5;
}

.sticky-bottom-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}
