@import 'theme.less';
@import 'mixins.less';
@import "variables.less";

#form-hero {
  background-color: #fafafa;
  height: 65vh;
  max-height: 568px;
  min-height: 400px;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(to right, rgba(0, 0, 0, .15), rgba(0, 0, 0, 0));
  }

  .hero-container {
    margin: auto;
    top: 0;
    bottom: 0;
    height: 340px;

    @media (min-width: 477px) {
      height: 310px;
    }

    @media (min-width: 768px) {
      height: 223px;
    }
  }

  .ie8 & {
    min-height: 500px;
  }
}

.mask {
  background: rgba(0, 0, 0, 0.10);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.main-content > .mask {
  background: none;
}

.hero {
  /**IE 8 **/
  background-size: cover;
  background-position: 50% 50%;
  color: #ffffff;
  position: relative;
  min-height: 150px;

  @media (min-width: 990px) {
    min-height: 360px;
  }

  .container {
    top: 40%;
    left: 0;
    right: 0;
    position: absolute;
  }

  h1, h2 {
    text-align: center;
    text-shadow: 0 0 14px rgba(0, 0, 0, 0.50);
  }

  h1 {
    font-size: 38px;

    @media (min-width: @screen-sm) {
      font-size: 54px;
    }
    margin: 0;
  }

  h2 {
    font-size: 20px;
    margin: 0;

    @media (min-width: @screen-sm) {
      font-size: 26px;
    }

    font-weight: normal;
    display: inline-block;
  }

  .save {
    svg {
      vertical-align: text-bottom;
      display: inline-block;
      height: 24px;
      width: 35px;

      @media (min-width: @screen-sm) {
        margin-top: 25px;
        vertical-align: inherit;
      }
    }
  }

  .connect {
    margin-top: 8px;

    @media (min-width: @screen-sm) {
      margin-top: 24px;
    }
  }

  .no-obligation {
    margin-top: 4px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;

    @media (min-width: @screen-sm) {
      margin-top: 8px;
    }

    .checkmark {
      font-size: 13px;
      color: #17ab46;
    }
  }

  .international-title {
    color: #3a3a3a;
    font-size: 17px;

    @media (min-width: @screen-sm) {
      display: flex;
      align-items: center;
      height: 56px;
      padding: 0;
    }
  }
}

.slab {
  margin-top: 24px;
  padding: 16px;
  border-radius: 4px;
  background: #ffffff;

  @media (min-width: @screen-sm) {
    margin-top: 48px;
  }

  // region HOME-3816 Ultimate hero position
  @media (max-width: @screen-sm) {
    max-width: 560px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  // endregion

  input, select, button {
    width: 100%;
    border-radius: 2px;
    height: 56px;
    border: none;
  }

  input {
    .placeholder({
      color: @home-gray-400
    });
    background-color: white;

    &:hover {
      border: 1px solid #1493C8;
    }

    &:focus {
      border: 2px solid #1493C8;
    }

  }

  input, select {
    color: @home-gray-700;
    padding: 15px 19px;
    border-radius: 4px;
    border: 1px solid #DCDFE0;
    font-size: 22px;
    box-shadow: none !important;
    @media (max-width: @screen-sm) {
      font-size: 20px;
    }
  }

  .loader {
    background-image: url('/build/img/loader.gif');
    background-color: #ffffff;
    background-position: center right 20px;
    background-repeat: no-repeat;
  }

  button {
    border: 1px solid #1ABD5E;
    font-size: 22px;
    font-weight: 700;
    border-radius: 4px;
  }
}

// region HOME-3070 landing page experiment
#form-hero.experimental-hero {
  color: @home-gray-600;
  background: none;
  height: unset;
  max-height: unset;
  max-width: 1200px;
  margin: auto;

  // region HOME-3152
  &.hero-lp-experiment-v3 {
    @media (max-width: @screen-sm) {
      .hero-main-container {
        min-height: unset;

        .heading-container {
          padding: 32px 16px 0 16px;

          h1, h2 {
            text-align: center;
            width: 100%;
          }
        }

        .image-container {
          display: none;
        }
      }
    }
    @media (min-width: @screen-sm) {
      .hero-main-container {
        align-items: start;
      }
    }
  }

  // endregion

  &::before {
    display: none;
  }

  h1, h2 {
    text-align: left;
    text-shadow: unset;
  }

  h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 8px;
    color: @home-gray-700;
    @media (min-width: @screen-sm) {
      font-size: 48px;
      line-height: 48px;
      margin: 0 0 16px 0;
    }
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
    color: @home-gray-600;
    @media (min-width: @screen-sm) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .hero-main-container {
    min-height: 480px;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 40px 0;

    @media (max-width: @screen-sm) {
      padding: 0;
      margin-bottom: 16px;
    }

    @media (min-width: @screen-sm) {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      gap: 6.67%;

      border-bottom: 1px solid @home-gray-200;
      width: 720px;
    }

    @media (min-width: @screen-md) {
      width: 940px;
    }

    @media (min-width: @screen-lg) {
      width: 100%;
    }

    .image-container {
      height: 200px;
      width: 100%;
      @media (min-width: @screen-sm) {
        flex: 1;
        height: 360px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (min-width: @screen-sm) {
          border-radius: 8px;
        }
      }
    }

    .heading-container {
      padding: 16px 16px 0;
      @media (min-width: @screen-sm) {
        padding: 0;
        flex: 1;
        min-width: 424px;
      }

      .slab {
        margin-top: 16px;
        background: #DFF0D8;
        border-radius: 4px;

        input {
          height: 52px;
          padding: 15px 16px;
          border-color: #D1D4D6;
          border-radius: 1px;
          color: @home-gray-400;
          font-size: 18px;
          line-height: 20px;
        }

        button {
          border-radius: 1px;
          border: none;
          padding: 0;
          height: 52px;
          font-size: 24px;

          &::after {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 12H20.5M20.5 12L13.5 5M20.5 12L13.5 19' stroke='white' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }

        @media (min-width: @screen-sm) {
          margin-top: 24px;
        }
      }

      .no-obligation {
        text-align: left;
        font-weight: 400;
        margin-top: 16px;
        display: flex;
        gap: 4px;
        flex-direction: column;
        padding-bottom: 24px;
        border-bottom: 1px solid @home-gray-200;
        @media (min-width: @screen-sm) {
          border-bottom: none;
          padding-bottom: 0;
        }

        img {
          margin-right: 8px;
        }
      }
    }
  }
}

// endregion

// region HOME-3816 Ultimate hero position
#form-hero.ultimate-position-hero {
  max-height: unset;
  height: unset;
  min-height: unset;
  background-color: white;

  .hero-container {
    position: relative;
    width: inherit;
    height: unset;
    display: block;
    border-bottom: 1px solid @home-gray-200;

    .text-block,
    .image-block {
      margin: 16px 0;
    }
  }

  &::before {
    display: none;
  }

  .text-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: @home-gray-700;
    padding: 0;

    h1 {
      font-size: 32px;
      text-shadow: none;
      margin-bottom: 16px;
    }

    h2 {
      font-size: 19px;
      font-weight: 400;
      text-shadow: none;
      margin-bottom: 32px;
      color: @home-gray-500;
    }

    .slab {
      margin-bottom: 16px;
      margin-top: 0;
      padding: 16px;
      background: @home-green-light;
      border-radius: 4px;
      input {
        font-size: 20px !important;
      }
    }

    .no-obligation {
      margin-top: 0;
      font-size: 16px;
      color: @home-gray-500;
      font-weight: 400;
    }
  }

  .image-block {
    background-size: cover;
    background-position: center;
    height: 100%;
    min-height: 408px;
    border-radius: 4px;
    padding: 0;
  }

  // mobile (The winner should be applied on the component level after the experiment is completed)
  @media (max-width: @screen-sm) {
    // var B
    &.ultimate-hero-b {
      .hero-container {
        flex-direction: column-reverse;
        display: flex;
        padding: 0;

        .text-block {
          padding: 0 8px;
          margin: 40px 16px 0 16px;
        }

        .image-block {
          border-radius: 0;
          margin-top: 0;
          height: 200px;
        }
      }
    }

    // var C (default mobile)
    .hero-container {
      padding: 28px 24px 0 24px;
      border-bottom: none;
      .text-block {
        margin-top: 20px;
        margin-bottom: 0;
        padding-bottom: 32px !important;
        border-bottom: 1px solid @home-gray-200;

        h1 {
          margin-bottom: 8px;
          font-size: 36px !important;
          @media (max-width: @screen-xs) {
            font-size: 28px !important;
          }
        }

        h2 {
          margin-bottom: 24px;
        }

        .slab {
          margin-bottom: 8px;
        }

        .no-obligation {
          font-size: 14px;
        }

      }

      .image-block {
        min-height: 160px;
        margin: 0;
      }
    }

    // var D
    &.ultimate-hero-d {
      .hero-container {
        .image-block {
          display: none;
        }
      }
    }

    &:not(.ultimate-hero-b):not(.ultimate-hero-d) {
      .hero-container {
        .text-block {
          border-bottom: none !important;
        }
      }
    }

  }

  // 922 - 1199
  @media (max-width: @screen-lg) {
    .text-block {
      h1 {
        font-size: 44px !important;
      }
    }

    @media (min-width: @screen-md) {
      .hero-container {
        gap: 64px !important;
      }

      .text-block {
        .slab {
          form {
            flex-direction: column !important;

            .lead {
              margin-bottom: 8px !important;
              margin-right: 0 !important;
            }
          }
        }
      }
    }

  }

  // tablet
  @media (min-width: @screen-sm) and (max-width: @screen-md) {
    .hero-container {
      gap: 40px !important;
      // These styles should be applied on the component level after the experiment is completed
      .text-block {
        h1 {
          font-size: 40px !important;
        }
        .slab {
          form {
            flex-direction: column !important;

            .lead {
              margin-bottom: 8px !important;
              margin-right: 0 !important;
            }
          }
        }
      }
    }
  }

  // desktop
  @media (min-width: @screen-sm) {
    .hero-container {
      display: flex;
      gap: 80px;
      min-height: 520px;

      .text-block,
      .image-block {
        margin: auto 0;
      }
    }

    .text-block {
      h1 {
        font-size: 48px;
        text-align: start;
      }

      h2 {
        font-size: 24px;
        text-align: start;
      }

      .slab {
        padding: 24px;
      }

      .no-obligation {
        text-align: start;
      }
    }
  }

}

// endregion